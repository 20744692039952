<template>
  <div class="card mb-5 mb-xl-10">
    <div
      class="card-header border-0 cursor-pointer bg-success"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#entity_organogram"
      aria-expanded="true"
      aria-controls="form"
    >
      <div class="card-title m-0">
        <h3 class="fw-bolder text-light m-0">Role</h3>
      </div>
    </div>

    <div id="entity_organogram" class="collapse show" v-loading="loading">
      <div class="collapse show">
        <div class="card-body border-top p-4">
          <div class="current" data-kt-stepper-element="content">
          
            <div class="row">
              <div class="col-md-2">
                <label class="form-label required">Project</label>
                <br />
                <!--end::Label-->
                <el-select
                  class="form-select-solid"
                  placeholder="Select Project"
                  v-model="batch.entity_type_id"
                  @change="showTree"
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="entity in entityTypeList"
                    :key="entity.id"
                    :label="entity.name"
                    :value="entity.id"
                    >{{ entity.name }}</el-option
                  >
                </el-select>
              
            </div>
            <div class="col-md-4 justify-content-sm-end mt-8" >
                <button
                  type="button"
                  class="btn btn-success"
                  @click="adddesignation"
                  style="
                    padding-top: 8px !important;
                    padding-bottom: 8px !important;
                  "
                >
                  Add Role
                </button>
              </div>
            <div class="p-4" v-if="showTreeEntity === true">
              <div>
                <Tree
                  :nodes="treedata"
                  :search-text="searchText"
                  :use-icon="true"
                  show-child-count
                  @update:nodes="onUpdate"
                  @nodeClick="onNodeClick"
                />
              </div>
              <!-- <div class="divbutton" v-if="this.button">
                <button class="btn btn-sm btn-info m-4 p-3" @click="addRole">
                  <i class="fas fa-edit"></i>
                </button>
                <button class="btn btn-sm btn-warning m-4 p-3" @click="addRole">
                  <i class="fas fa-plus"></i>
                </button>
              </div> -->
            </div>
            </div>
                
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="editPanel">
    <Form @submit="editSubmit()" class="form" :validation-schema="RoleSchema">
      <div class="card mb-5 mb-xl-10">
        <div
          class="card-header border-0 cursor-pointer bg-success"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#user_account"
          aria-expanded="true"
          aria-controls="form"
        >
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Edit Designation</h3>
          </div>
        </div>

        <div id="user_account" class="collapse show">
          <div class="collapse show">
            <div class="card-body border-top p-9">
              <div data-kt-stepper-element="content">
                <div class="row">
                  <div class="col-md-2">
                    <label class="fs-6 fw-bold form-label required"
                      >Select Project</label
                    >
                    <el-select
                      class="form-select-solid"
                      placeholder="Select Project"
                      v-model="batch.entity_type_id"
                      filterable
                      style="width: 100%"
                      disabled
                    >
                      <el-option
                        v-for="entity in entityTypeList"
                        :key="entity.id"
                        :label="entity.name"
                        :value="entity.id"
                        >{{ entity.name }}</el-option
                      >
                    </el-select>
                  </div>
                  <!-- <div class="col-md-2" v-if="!editForm.parent_role_id">
                    <label class="fs-6 fw-bold form-label"
                      >Select Parent Role</label
                    >
                    <el-select
                      class="form-select-solid"
                      placeholder="Select Role"
                      v-model="batch.parent_role_id"
                      filterable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="role in entityTypeRole"
                        :key="role.id"
                        :label="role.label"
                        :value="role.id"
                        >{{ role.label }}</el-option
                      >
                    </el-select>
                  </div> -->
                   <!-- <div class="col-md-2" v-if="editForm.parent_role_id">
                    <label class="fs-6 fw-bold form-label"
                      >Select Parent Role</label
                    >
                     <Field
                      v-model="editForm.parent_role_name"
                      name="role_name"
                      class="form-control form-control-sm"
                      type="name"
                      readonly
                    />
                  </div> -->
                   <div class="col-md-2">
                    <label class="fs-6 fw-bold form-label"
                      >Select Parent Role</label
                    >
                    <el-select
                      class="form-select-solid"
                      placeholder="Select Role"
                      v-model="editForm.parent_role_id"
                      filterable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="role in entityTypeRole"
                        :key="role.id"
                        :label="role.label"
                        :value="role.id"
                        selected
                        >{{ role.label }}</el-option
                      >
                    </el-select>
                  </div>
                  <div class="col-md-2">
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Role Name</span>
                    </label>
                    <Field
                      v-model="editForm.role_name"
                      name="role_name"
                      class="form-control form-control-sm"
                      type="name"
                    />
                  </div>
                  <div class="col-md-2">
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Role Title</span>
                    </label>
                    <Field
                      name="role_title"
                      class="form-control form-control-sm"
                      type="name"
                      v-model="editForm.role_title"
                    />
                  </div>
                  <div class="col-md-2">
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Level</span>
                    </label>
                    <Field
                      name="level"
                      class="form-control form-control-sm"
                      type="name"
                      v-model="editForm.level"
                    />
                  </div>

                  <div class="col-md-2 mt-8">
                    <button
                      :data-kt-indicator="loading ? 'on' : null"
                      class="btn btn-success"
                      style="
                        padding-top: 8px !important;
                        padding-bottom: 8px !important;
                      "
                      type="submit"
                    >
                      <span v-if="!loading" class="indicator-label">
                        Submit
                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                          <inline-svg
                            src="media/icons/duotune/arrows/arr064.svg"
                          />
                        </span>
                      </span>
                      <span v-if="loading" class="indicator-progress">
                        Please wait...
                        <span
                          class="
                            spinner-border spinner-border-sm
                            align-middle
                            ms-2
                          "
                        ></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
  <!-- <div v-if="addrole || !entityTypeRole.length"> -->
  <div v-if="addPanel">
    <Form @submit="formSubmit()" class="form" :validation-schema="RoleSchema">
      <div class="card mb-5 mb-xl-10">
        <div
          class="card-header border-0 cursor-pointer bg-success"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#user_account"
          aria-expanded="true"
          aria-controls="form"
        >
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Add Designation</h3>
          </div>
        </div>

        <div id="user_account" class="collapse show">
          <div class="collapse show">
            <div class="card-body border-top p-9">
              <div data-kt-stepper-element="content">
                <div class="row">
                  <div class="col-md-2">
                    <label class="fs-6 fw-bold form-label required"
                      >Select Project</label
                    >
                    <el-select
                      class="form-select-solid"
                      placeholder="Select Project"
                      v-model="batch.entity_type_id"
                      filterable
                      style="width: 100%"
                      disabled
                    >
                      <el-option
                        v-for="entity in entityTypeList"
                        :key="entity.id"
                        :label="entity.name"
                        :value="entity.id"
                        >{{ entity.name }}</el-option
                      >
                    </el-select>
                  </div>
                  <div class="col-md-2">
                    <label class="fs-6 fw-bold form-label"
                      >Select Parent Role</label
                    >
                    <el-select
                      class="form-select-solid"
                      placeholder="Select Role"
                      v-model="batch.parent_role_id"
                      filterable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="role in entityTypeRole"
                        :key="role.id"
                        :label="role.label"
                        :value="role.id"
                        >{{ role.label }}</el-option
                      >
                    </el-select>
                  </div>
                  <div class="col-md-2">
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Role Name</span>
                    </label>
                    <Field
                      v-model="batch.role_name"
                      name="role_name"
                      class="form-control form-control-sm"
                      type="name"
                    />
                  </div>
                  <div class="col-md-2">
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Role Title</span>
                    </label>
                    <Field
                      name="role_title"
                      class="form-control form-control-sm"
                      type="name"
                      v-model="batch.role_title"
                    />
                  </div>
                  <div class="col-md-2">
                    <label class="d-flex align-items-center form-label">
                      <span class="required">Level</span>
                    </label>
                    <Field
                      name="level"
                      class="form-control form-control-sm"
                      type="name"
                      v-model="batch.level"
                    />
                  </div>

                  <div class="col-md-2 mt-8">
                    <button
                      :data-kt-indicator="loading ? 'on' : null"
                      class="btn btn-success"
                      style="
                        padding-top: 8px !important;
                        padding-bottom: 8px !important;
                      "
                      type="submit"
                    >
                      <span v-if="!loading" class="indicator-label">
                        Submit
                        <span class="svg-icon svg-icon-3 ms-2 me-0">
                          <inline-svg
                            src="media/icons/duotune/arrows/arr064.svg"
                          />
                        </span>
                      </span>
                      <span v-if="loading" class="indicator-progress">
                        Please wait...
                        <span
                          class="
                            spinner-border spinner-border-sm
                            align-middle
                            ms-2
                          "
                        ></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { ref } from "vue";
import Tree from "vue3-tree";
import "vue3-tree/dist/style.css";
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from '@/mixin/apiMixin';

export default {
  mixins:[apiEndpoint],
  components: {
    Tree,
    Form,
    Field,
  },
  data() {
    return {
      loading: false,
      addPanel:false,
      entityTypeList: {},
      entityTyperoleList: {},
      editForm: {
        id:"",
        role_name: "",
        role_title: "",
        parent_role_name: "",
        parent_role_id: "",
        level: "",
      },
      entityTypeRole: [],
      batch: {},
      treedata: [],
      batch: {
        entity_type_id: "",
        role_name: "",
        parent_role_id: "",
        role_title: "",
        level: "",
      },
      button: false,
      editPanel: false,
      addrole: false,
      showTreeEntity: false,
    };
  },
  async created() {
    //await this.getDistrictForEmp();
    await this.getEntityTypes();
  },
  methods: {
    async showTree() {
      this.loading = true;
      this.getEntityTypesRoles();
      this.showTreeEntity = true;
    },
    listToTree(list) {
      let map = {};
      let roots = [];

      list.forEach((v, i) => {
        map[v.id] = i;
        list[i].nodes = [];
      });

      list.forEach((v) =>
        v.parentId !== null
          ? list[map[v.parentId]].nodes.push(v)
          : roots.push(v)
      );

      return roots;
    },

    adddesignation(){
      this.addPanel=true;
      this.editPanel=false;
    },

    async getEntityTypesRoles() {
      this.batch.parent_role_id = "";
      await ApiService.get(
        "entity_type_role/role/allroles?entity_type_id=" +
          this.batch.entity_type_id
      )
        .then((response) => {
          this.loading = false;
          this.entityTypeRole = [];
          this.entityTypeRole = response.data.data.role;
          let arrayResponse = this.entityTypeRole.map((item) => ({ ...item }));
          this.treedata = this.listToTree(arrayResponse);
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async formSubmit() {
      let data = {
        batch: this.batch,
      };
      await ApiService.post(this.VUE_APP_ENTITY_TYPE_ROLE_SAVE, data)
        .then((response) => {
          this.getEntityTypesRoles();
          console.log(response);
          if (response.data.status === "success") {
            Swal.fire({
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.batch = {
                entity_type_id: this.batch.entity_type_id,
                role_name: "",
                parent_role_id: "",
                role_title: "",
                level: "",
              };
            });
          } else {
            Swal.fire({
              text: response.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            }).then(() => {
              this.batch = {
                entity_type_id: "",
                role_name: "",
                parent_role_id: "",
                role_title: "",
                level: "",
              };
            });
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    onNodeClick(node) {
      this.editPanel = true;
      this.addPanel=false;
      ApiService.get(this.VUE_APP_ENTITY_TYPE_ROLE_GET_BY_ID+"?id=" + node.id)
        .then((response) => {
          console.log(response);
          this.editForm.id=response.data.data.id;
          this.editForm.role_name = response.data.data.role_name;
          this.editForm.role_title = response.data.data.role_title;
          if (response.data.data.entity_type_role.length) {
            this.editForm.parent_role_name =
              response.data.data.entity_type_role[0].role_name;
            this.editForm.parent_role_id =
              response.data.data.entity_type_role[0].id;
          }

          this.editForm.level = response.data.data.level;
          console.log(this.editForm);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async editSubmit()
    {
      let data=new FormData();
      data.set("entity_type_id",this.batch.entity_type_id);
      data.set("parent_role_id",this.editForm.parent_role_id);
      data.set("role_name",this.editForm.role_name);
      data.set("role_title",this.editForm.role_title);
      data.set("level",this.editForm.level);
      data.set("id",this.editForm.id);
      ApiService.post(this.VUE_APP_ENTITY_TYPE_ROLE_UPDATE,data).then((response)=>{
        if (response.data.status === "success") {
          this.getEntityTypesRoles();
            Swal.fire({
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.editForm = {
                entity_type_id: "",
                role_name: "",
                parent_role_id: "",
                role_title: "",
                level: "",
              };
            });
          } else {
            Swal.fire({
              text: response.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            }).then(() => {
              this.editForm = {
                entity_type_id: "",
                role_name: "",
                parent_role_id: "",
                role_title: "",
                level: "",
              };
            });
          }
      }).catch((response)=>{
        console.log(response)
      })

    },
    async getEntityTypes() {
      await ApiService.get("configurations/entity_type/list")
        .then((response) => {
          //console.log(response);
          this.entityTypeList = response.data.data;
        })
        .catch(({ response }) => {
          //console.log(response);
        });
    },
  },
  setup() {
    const searchText = ref("");
    const onNodeExpanded = (node, state) => {
      // console.log("state: ", state);
      //console.log("node: ", node);
    };

    const onUpdate = (nodes) => {
      //console.log("nodes:", nodes);
    };
    // const RoleSchema = Yup.object().shape({
    //   //presentDistrict: Yup.string().required().label("Present District"),
    //   entity_type_id: Yup.string().required().label("Entity Type"),
    //   role_name: Yup.string().required().label("Role Name"),
    //   role_title: Yup.string().required().label("Role Title"),
    //   // nid:Yup.number().test('len', 'Must be exactly 5 characters', (nid) => { if(nid) return nid.toString().length === 8; }),
    // });
    // const onNodeClick= (node)=>{

    // console.log(node.id);
    // };

    return {
      // onNodeClick,
      searchText,
      onNodeExpanded,
      onUpdate,
    };
  },
};
</script>

<style lang="scss">
.tree {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 5px;
}
.tree ul {
  margin-left: 1em;
  position: relative;
}
.tree ul ul {
  margin-left: 0.5em;
}
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
}

.tree li {
  content: "<button>hele</button>";
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: #369;
  font-weight: 700;
  position: relative;
}
.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0;
}
.tree ul li:last-child:before {
  background: #fff;
  height: auto;
  top: 1em;
  bottom: 0;
}
.indicator {
  margin-right: 5px;
}
.tree li a {
  text-decoration: none;
  color: #369;
}
</style>
